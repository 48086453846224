@import 'assets/styles/hierarchical.css';
@import 'assets/styles/fullpage.scss';

@layer tailwind-base, primeng, tailwind-components, tailwind-utilities;

@layer tailwind-base {
  @tailwind base;

  html {
    font-family: "Poppins", system-ui;
  }
}

@layer tailwind-components {
  @tailwind components;
}

@layer tailwind-utilities {
  @tailwind utilities;
  * {
    scrollbar-width: thin;
    scrollbar-color: auto;
  }

  td {
    min-height: 2rem;
    padding: 0.75rem 1rem;
  }

  tr {
    min-height: 57px;
  }

  .p-datatable-paginator-top {
    justify-content: flex-end;
  }
}

.search-list-row {
  padding: 5px;
  border-bottom: 1px dotted;
}

.page-header-step {
  text-transform: capitalize;
  margin-right: 0.5rem;
  padding-right: 0.5rem;
}

.page-header-step:not(:last-child) {
  border-right: 2px solid theme('colors.primary.light');
}

iconify-icon {
  display: inline-block;
  width: 1em;
  height: 1em;
}
