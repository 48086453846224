
.hierarchical {
  position: relative;
}
.hierarchical.level-1 {
  padding-left: 40px;
}
.hierarchical.level-2 {
  padding-left: 56px;
}
.hierarchical.level-3 {
  padding-left: 72px;
}

.hierarchical.has-parent:before {
  position: absolute;
  content: '';
  border-left: dotted 2px theme('colors.accent.DEFAULT');
  border-bottom: dotted 2px theme('colors.accent.DEFAULT');
  top: 0;
  height: 50%;
  left: 16px;
  width: 16px;
}

.hierarchical.has-sibling:after {
  position: absolute;
  content: '';
  border-left: dotted 2px theme('colors.accent.DEFAULT');
  top: 50%;
  bottom: 0;
  left: 16px;
}


.hierarchical.has-child:after {
  position: absolute;
  content: '';
  border-left: dotted 2px theme('colors.accent.DEFAULT');
  top: 60%;
  bottom: 0;
  left: 16px;
}

.hierarchical.has-child.level-1:after,
.hierarchical.has-sibling.level-2:after,
.hierarchical.has-parent.level-2:before {
  left: 32px;
}

.hierarchical.has-child.level-2:after,
.hierarchical.has-sibling.level-3:after,
.hierarchical.has-parent.level-3:before {
  left: 48px;
}
